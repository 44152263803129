// const base = process.env.NODE_ENV === 'production' ? '1' : 'http://139.196.180.99:8088/api/'
// let env = process.env.NODE_ENV

let ip = ''
let ws = ''


// // 线上
// if (env === 'production') {
//     ip = 'https://jyf-web.yunqishang.net'
//     ws = 'wss://jyf-web.yunqishang.net:17777'
// }
// // 测试
// else {
    ip = 'https://jyf-dev-js-web.yunqishang.net:8443'
    ws = 'wss://jyf-dev-js-web.yunqishang.net:17777'
// }


// 公共
const uaa = `${ip}/api/uaa`
const upms = `${ip}/api/upms`
const law = `${ip}/api/law`


export default {
    ws,
    queryFamily: `${law}/lineup/query/family`, //待会见记录
    lineupEnd: `${law}/lineup/end`, //待会见记录
    token: `${uaa}/oauth/token`, //token 登录
    roleInfo: `${upms}/system/users/self`, // 个人信息
    cityInService: `${upms}/system/areas/city-in-service`, // 城市列表
    getlawyerinfo: `${law}/lawyer/detail`,//获取个人资料
    advisoryOrderAdd: `${law}/advisory/order/add`,//获取个人资料
    agoraToken: `${law}/vm/lawyer-family/agora-token`,//获取个人资料

    getSupervisionCenter: `${upms}/system/district/getSupervisionCenter`, // 获取市下面的监管中心
    getPrisonList: `${upms}/system/district/getPrisonList`, // 获取对应监管中心下面的看守所

    logout: `${uaa}/token`, // 登出
}
